import React, { useState, navigate } from 'react';
import axios from 'axios';
import Modal from 'react-modal';

function AddServiceFormModal({ isOpen, onClose }) {
  const [plaka, setPlaka] = useState('');
  const [surucuAdi, setSurucuAdi] = useState('');
  const [surucuIletisim, setSurucuIletisim] = useState('');
  const [yardimciAdi, setYardimciAdi] = useState('');
  const [yardimciIletisim, setYardimciIletisim] = useState('');
  const [durum, setDurum] = useState(false);
  const [warning, setWarning] = useState('');

  const onSubmit = async ({ plaka, surucuAdi, surucuIletisim, yardimciAdi, yardimciIletisim, durum }) => {
    try {
        const response = await axios.post('https://servisrehberim.com/admin/addService.php', {
            plaka: plaka,
            surucuAdi: surucuAdi,
            surucuIletisim: surucuIletisim,
            yardimciAdi: yardimciAdi,
            yardimciIletisim: yardimciIletisim,
            durum: durum ? "Aktif" : "Pasif",
        },
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        if (response.status === 200) {
            // Handle success
            onClose();
            window.location.reload();
        } else {
            // Handle error
            setWarning('Bir hata oluştu. Lütfen tekrar deneyin.');
        }
    } catch (error) {
        setWarning('Bir hata oluştu. Lütfen tekrar deneyin.');
        console.error('An error occurred', error);
    }
};

  const handleSubmit = (event) => {
    event.preventDefault();
    // Perform form validation or other actions here
    onSubmit({ plaka, surucuAdi, surucuIletisim, yardimciAdi, yardimciIletisim, durum });
  };

  return (
    <div class={`modal ${isOpen ? 'modal-open' : ''}`}>
      <Modal isOpen={isOpen} onRequestClose={onClose}>
        <div className="modal-header">
          <h2 class='font-bold text-3xl'>Servis Ekle</h2>
          <button className="close-button" onClick={onClose}>✖</button>
        </div>
        <div className="modal-body">
          <form onSubmit={handleSubmit}>
            <div className="space-y-5">
              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-4">
                  <label htmlFor="plaka" className="block text-lg font-medium leading-6 text-gray-900">Araç Plakası</label>
                  <input type="text" id="plaka" name="plaka" value={plaka} onChange={(e) => setPlaka(e.target.value)} className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" required />
                </div>
                <div className="sm:col-span-4">
                  <label htmlFor="surucuAdi" className="block text-lg font-medium leading-6 text-gray-900">Sürücü Adı</label>
                  <input type="text" id="surucuAdi" name="surucuAdi" value={surucuAdi} onChange={(e) => setSurucuAdi(e.target.value)} className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" required />
                </div>
                <div className="sm:col-span-4">
                  <label htmlFor="surucuIletisim" className="block text-lg font-medium leading-6 text-gray-900">Sürücü İletişim</label>
                  <input type="text" id="surucuIletisim" name="surucuIletisim" value={surucuIletisim} onChange={(e) => setSurucuIletisim(e.target.value)} className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" required />
                </div>
                <div className="sm:col-span-4">
                  <label htmlFor="yardimciAdi" className="block text-lg font-medium leading-6 text-gray-900">Yardımcı Adı</label>
                  <input type="text" id="yardimciAdi" name="yardimciAdi" value={yardimciAdi} onChange={(e) => setYardimciAdi(e.target.value)} className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" required />
                </div>
                <div className="sm:col-span-4">
                  <label htmlFor="yardimciIletisim" className="block text-lg font-medium leading-6 text-gray-900">Yardımcı İletişim</label>
                  <input type="text" id="yardimciIletisim" name="yardimciIletisim" value={yardimciIletisim} onChange={(e) => setYardimciIletisim(e.target.value)} className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" required/>
                </div>
              </div>
            </div>
            <div className="mt-10 space-y-5">
              <fieldset>
                <legend className="text-lg font-semibold leading-6 text-gray-900">Durum</legend>
                <div className="mt-6 space-y-6">
                  <div className="relative flex gap-x-3">
                    <input id="durum" name="durum" type="checkbox" checked={durum} onChange={(e) => setDurum(e.target.checked)} className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" />
                    <div className="text-lg leading-6">
                      <label htmlFor="durum" className="font-medium text-gray-900">Aktif Mi?</label>
                      <p className="text-gray-500">Öğrenci servisi kullanmaya devam ediyorsa işaretleyin.</p>
                    </div>
                  </div>
                </div>
              </fieldset>
            </div>
            <div className="flex justify-end space-x-4">
              <button type="button" className="btn btn-secondary text-lg border border-solid border-red-500 bg-red-500 rounded-lg w-24 h-10 font-bold text-white" onClick={onClose}>İptal</button>
              <button type="submit" className="btn btn-primary text-lg border border-solid border-blue-800 bg-blue-800 rounded-lg w-24 h-10 font-bold text-white" onClick={handleSubmit}>Kaydet</button>
            </div>
            <div className="flex flex-row justify-end space-x-4 pt-5">
                { warning && <p className="text-red-500 justify-end">{warning}</p> }
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}

export default AddServiceFormModal;