import React from 'react';
import './landing.css'; // Import the updated CSS file
import landingImage from './landingbackground.jpeg'; // Import the image

function getCookie(name) {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    const cookieName = cookie.substring(0, cookie.indexOf('='));

    if (cookieName === name) {
      return cookie.substring(cookieName.length + 1);
    }
  }

  return null;
}

const admnTurCookie = getCookie('ADMN_TUR');

const checkAdminCookie = () => {
  // Implement your logic to check for the admin cookie here
  // For example, you might use localStorage or a cookie library
  const isAdmin = admnTurCookie;
  return isAdmin;
};

const checkIsLoggedIn = () => {
  const isValidCookie = getCookie('PHPSESSID');
  return isValidCookie;
};



const Landing = () => {
  if (checkIsLoggedIn() && checkAdminCookie() && window.location.pathname != '/services') {
    window.location.href = '/services';
  }
  return (
    <div className="u-body u-xl-mode">
      <section 
        className="u-align-center u-clearfix u-image u-shading u-section-1" 
        id="sec-ec46"
        style={{
          backgroundImage: `url(${landingImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '100vh', // Ensure the section has height
        }}
      >
        <div className="u-clearfix u-sheet u-sheet-1">
          <p className="u-text u-text-default u-text-2">
            <span className="button-container">
              <a 
                className="sign-up-btn"
                href="https://servisrehberim.com/kayit.php" 
              >
                HEMEN KAYIT OL
              </a>
              <a 
                className="sign-in-btn"
                href="https://servisrehberim.com/login.php" 
              >
                GİRİŞ YAP
              </a>
            </span>
          </p>
        </div>
      </section>
    </div>
  );
};

export default Landing;
