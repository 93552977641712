import React, { useEffect, useState } from 'react';

import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useLocation } from 'react-router-dom';

const user = {
  name: 'Admin',
  email: 'admin@example.com',
  imageUrl: 'https://avatars.dicebear.com/api/human/admin.svg',
}

function getCookie(name) {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    const cookieName = cookie.substring(0, cookie.indexOf('='));

    if (cookieName === name) {
      return cookie.substring(cookieName.length + 1);
    }
  }

  return null;
}

const admnTurCookie = getCookie('ADMN_TUR');

const checkAdminCookie = () => {
  // Implement your logic to check for the admin cookie here
  // For example, you might use localStorage or a cookie library
  const isAdmin = admnTurCookie;
  return isAdmin;
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}
const navigation = [
  { name: 'Servis Bilgileri', href: '/services' },
  { name: 'Öğrenci Bilgileri', href: '/students'},
  { name: 'Güzergah Yönetimi', href: '/guzergah'},
  { name: 'Konum Takibi', href: '/route-tracking'}
]

export default function Dashboard() {

  const current = useLocation().pathname;
  const isAdmin = checkAdminCookie();
  return (
    <>
      <div className="min-h-full">
        <Disclosure as="nav" id={'navbar'}>
          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
            <div className="flex h-16 items-center justify-between">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <img
                    alt="Your Company"
                    src={require('./Assets/logo.jpeg')}
                    className="h-10 w-48"
                  />
                </div>
                <div className="hidden md:block">
                  <div className="ml-10 flex items-baseline space-x-4">
                    {!isAdmin && (<a
                        key={'Takip Et'}
                        href={'/takip-et'}
                        aria-current={current === "/takip-et" ? 'page' : undefined}
                        className={classNames(
                          current === "/takip-et" ? 'bg-gray-700 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'rounded-md px-3 py-2 text-sm font-bold',
                        )}
                        >
                        {'Takip Et'}
                      </a>)}
                    {isAdmin && navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        aria-current={current === item.href ? 'page' : undefined}
                        className={classNames(
                          current === item.href ? 'bg-gray-700 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                          'rounded-md px-3 py-2 text-sm font-bold',
                        )}
                        >
                        {item.name}
                      </a>
                    ))}
                  </div>
                </div>
              </div>
              <div className="hidden md:block">
                <div className="ml-4 flex items-center md:ml-6">
                  {/* Profile dropdown */}
                  <Menu as="div" className="relative ml-3">
                      <MenuItem>
                        <a href="/logout.php" className="block px-4 py-2 text-sm font-bold text-gray-300 hover:bg-gray-700 rounded-lg">Çıkış</a>
                      </MenuItem>
                  </Menu>
                </div>
              </div>
              <div className="-mr-2 flex md:hidden">
                {/* Mobile menu button */}
                <DisclosureButton className="group relative inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                  <span className="absolute -inset-0.5" />
                  <span className="sr-only">Open main menu</span>
                  <Bars3Icon aria-hidden="true" className="block h-6 w-6 group-data-[open]:hidden" />
                  <XMarkIcon aria-hidden="true" className="hidden h-6 w-6 group-data-[open]:block" />
                </DisclosureButton>
              </div>
            </div>
          </div>

          <DisclosurePanel className="md:hidden">
            <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
              {navigation.map((item) => (
                <DisclosureButton
                  key={item.name}
                  as="a"
                  href={item.href}
                  aria-current={item.current ? 'page' : undefined}
                  className={classNames(
                    item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                    'block rounded-md px-3 py-2 text-base font-medium',
                  )}
                >
                  {item.name}
                </DisclosureButton>
              ))}
            </div>
          </DisclosurePanel>
        </Disclosure>

        {navigation.map((item) => item.href === current &&
          <header className="bg-white shadow">
            <div className="mx-auto max-w-7xl px-2 py-6 sm:px-3 lg:px-1">
                <h1 className="text-3xl font-bold tracking-tight text-gray-900">{item.name}</h1>
            </div>
          </header>
        )}

        <main>
          <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">{/* Your content */}</div>
        </main>
      </div>
    </>
  )
}
