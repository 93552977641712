import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Dashboard from './Dashboard';
import Services from './Services';
import Students from './Students';
import GuzergahYonetimi from './GuzergahYonetimi';
import Landing from './Landing';
import RouteTracking from './RouteTracking';
import TakipEt from './TakipEt';
import KonumPaylas from './KonumPaylas';

function App() {
  function getCookie(name) {
    const cookies = document.cookie.split(';');
  
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      const cookieName = cookie.substring(0, cookie.indexOf('='));
  
      if (cookieName === name) {
        return cookie.substring(cookieName.length + 1);
      }
    }
  
    return null;
  }
  
  const admnTurCookie = getCookie('ADMN_TUR');
  
  const checkAdminCookie = () => {
    // Implement your logic to check for the admin cookie here
    // For example, you might use localStorage or a cookie library
    const isAdmin = admnTurCookie;
    return isAdmin;
  };

  const checkIsLoggedIn = () => {
    const isValidCookie = getCookie('PHPSESSID');
    return isValidCookie;
  };

  const checkIsServiceHelper = () => {
    const isValidCookie = getCookie('SERVICE_HELPER');
    return isValidCookie;
  }

  const checkIsRegularUser = () => {
    // Implement your logic to check if the user is a regular user (not an admin)
    return !checkAdminCookie();
  };

  useEffect(() => {
    const isLoggedIn = checkIsLoggedIn();
    const isAdmin = checkAdminCookie() && isLoggedIn;
    
    if (!isLoggedIn && window.location.pathname != '/') {
      // Redirect to the landing page if the user is not logged in
      window.location.href = '/';
    }
  }, []);

  if (checkAdminCookie()) {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
        </Routes>
        {window.location.pathname != '/' && (<div>
          <Dashboard />
          <main>
            <Routes>
              <Route path="/services" element={<Services />} />
              <Route path="/students" element={<Students />} />
              <Route path="/guzergah" element={<GuzergahYonetimi />} />
              <Route path="/route-tracking" element={<RouteTracking />} />
            </Routes>
          </main>
        </div>)}
      </Router>
    );
  } else if (checkIsServiceHelper()) {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
        </Routes>
          {window.location.pathname != '/' && (<div>
          <Dashboard />
          <main>
            <Routes>
              <Route path="/konum-paylas" element={<KonumPaylas />} />
            </Routes>
          </main>
        </div>)}
      </Router>
    );
  } else {
    return (
      <Router>
        <Routes>
          <Route path="/" element={<Landing />} />
        </Routes>
          {window.location.pathname != '/' && (<div>
          <Dashboard />
          <main>
            <Routes>
              <Route path="/takip-et" element={<TakipEt />} />
            </Routes>
          </main>
        </div>)}
      </Router>
    );
  }
}

export default App;