import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import { FaMapMarkerAlt, FaCheck, FaStop } from 'react-icons/fa';

const KonumPaylas = () => {
  const [students, setStudents] = useState([]);
  const [selectedStudents, setSelectedStudents] = useState({});
  const [currentLocation, setCurrentLocation] = useState({ lat: null, lng: null });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSharingLocation, setIsSharingLocation] = useState(false);
  const [timeOfDay, setTimeOfDay] = useState(''); // Morning/evening state
  const [serviceId] = useState(() => getCookie('SERVICE_ID'));
  const intervalRef = useRef(null);

  const fetchStudents = useCallback(async () => {
    try {
      const response = await axios.get(`https://servisrehberim.com/admin/getServiceStudents.php?service_id=${serviceId}`);
      setStudents(response.data);
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  }, [serviceId]);

  const sendSmsForEntry = async (status) => {
    try {
      const formData = new FormData();
      formData.append('service_id', serviceId);
      formData.append('status', status); // 'entered' or 'not entered'
      formData.append('timestamp', new Date().toISOString());
      formData.append('inOrOut', status)
      formData.append('timeOfDay', timeOfDay);
  
      Object.keys(selectedStudents).forEach((id) => {
        formData.append(`students[${id}]`, selectedStudents[id] ? 'inside' : 'outside');
      });
  
      await axios.post('https://servisrehberim.com/admin/submitStudentStatus.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      alert(`SMS for ${status} service sent successfully!`);
    } catch (error) {
      console.error('Error sending SMS:', error);
      alert(`Error sending SMS for ${status} service.`);
    }
  };

  const sendStudentStatusData = async () => {
    try {
      const formData = new FormData();
      formData.append('service_id', serviceId);
      formData.append('timestamp', new Date().toISOString());
      formData.append('timeOfDay', timeOfDay);
  
      Object.keys(selectedStudents).forEach((id) => {
        formData.append(`students[${id}]`, selectedStudents[id] ? 'inside' : 'outside');
      });
  
      await axios.post('https://servisrehberim.com/admin/submitStudentStatus.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      console.log('Student status data sent successfully');
    } catch (error) {
      console.error('Error sending student status data:', error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!timeOfDay) {
      alert('Please select Morning or Evening');
      return;
    }
    setIsSubmitting(true);

    try {
      await sendStudentStatusData(); // Send student status data
      alert('Student statuses shared successfully!');
    } catch (error) {
      console.error('Error submitting data:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleLocationSharing = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const location = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          setCurrentLocation(location);
          sendLocationData(location);
        },
        (error) => {
          console.error('Error getting location:', error);
        }
      );
    } else {
      alert('Geolocation is not supported by this browser.');
    }
  };

  const sendLocationData = async (location) => {
    try {
      const formData = new FormData();
      formData.append('service_id', serviceId);  // Use serviceId from state
      formData.append('latitude', location.lat);
      formData.append('longitude', location.lng);
  
      await axios.post('https://servisrehberim.com/admin/setCurrentPosition.php', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Location data sent:', formData);
    } catch (error) {
      console.error('Error sending location data:', error);
    }
  };

  const handleLocationButtonClick = () => {
    if (!isSharingLocation) {
      setIsSharingLocation(true);
      handleLocationSharing(); // Send initial location immediately
      intervalRef.current = setInterval(() => {
        handleLocationSharing(); // Share location every 5 seconds
      }, 5000);
    } else {
      clearInterval(intervalRef.current); // Stop location sharing
      setIsSharingLocation(false);
    }
  };

  const handleSelectAll = () => {
    const allSelected = students.reduce((acc, student) => {
      acc[student.id] = true;
      return acc;
    }, {});
    setSelectedStudents(allSelected);
  };

  useEffect(() => {
    fetchStudents();

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [fetchStudents]);

  const handleCheckboxChange = (studentId) => {
    setSelectedStudents((prevState) => ({
      ...prevState,
      [studentId]: !prevState[studentId],
    }));
  };

  const handleTimeOfDayChange = (e) => {
    setTimeOfDay(e.target.value);
  };

  return (
    <div className="max-w-3xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h2 className="text-2xl font-bold text-center mb-4">Öğrenci Listesi</h2>

      <div className="flex justify-center mb-6">
        <button onClick={handleSelectAll} className="text-blue-500 font-semibold hover:text-blue-600 transition">
          Hepsini Seç
        </button>
      </div>

      <form onSubmit={handleSubmit} className="space-y-4">
        {students.length > 0 ? (
          students.map((student) => (
            <div key={student.id} className="flex items-center space-x-3 p-2 bg-gray-100 rounded-md shadow-sm hover:bg-gray-200 transition">
              <input
                type="checkbox"
                id={`student-${student.id}`}
                checked={!!selectedStudents[student.id]}
                onChange={() => handleCheckboxChange(student.id)}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded"
              />
              <label htmlFor={`student-${student.id}`} className="ml-2 text-gray-700 font-medium">
                {student.fullName}
              </label>
            </div>
          ))
        ) : (
          <p className="text-gray-600 text-center">Servise kayıtlı öğrenci bulunamadı.</p>
        )}

        {/* Morning and Evening Radio Buttons */}
        <div className="mt-6 text-center space-x-4">
          <label className="mr-4">
            <input
              type="radio"
              value="sabah"
              checked={timeOfDay === 'sabah'}
              onChange={handleTimeOfDayChange}
              className="mr-2"
            />
            Sabah
          </label>
          <label>
            <input
              type="radio"
              value="aksam"
              checked={timeOfDay === 'aksam'}
              onChange={handleTimeOfDayChange}
              className="mr-2"
            />
            Akşam
          </label>
        </div>

        <button type="submit" disabled={isSubmitting} className="w-full bg-blue-500 text-white font-bold py-2 px-4 rounded-md shadow-md hover:bg-blue-600 transition">
          {isSubmitting ? 'Gönderiliyor...' : 'Bilgi Gönder'}
        </button>
      </form>

      {/* Additional SMS Buttons for Entered/Not Entered Status */}
      <div className="mt-6 text-center">
        <button
          onClick={() => sendSmsForEntry('yes')}
          className="w-full bg-green-500 text-white font-bold py-2 px-4 rounded-md shadow-md hover:bg-green-600 transition mt-4"
        >
          Öğrenci Servise bindi SMS Gönder
        </button>
        <button
          onClick={() => sendSmsForEntry('no')}
          className="w-full bg-red-500 text-white font-bold py-2 px-4 rounded-md shadow-md hover:bg-red-600 transition mt-4"
        >
          Öğrenci Servise binmedi SMS Gönder
        </button>
      </div>

      {/* Location Sharing Button */}
      <div className="mt-6 text-center">
        <button
          onClick={handleLocationButtonClick}
          className={`w-full py-2 px-4 font-bold text-white rounded-md shadow-md hover:shadow-lg transition ${
            isSharingLocation ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'
          } flex items-center justify-center space-x-2`}
        >
          {isSharingLocation ? <FaStop /> : <FaMapMarkerAlt />}
          <span>{isSharingLocation ? 'Konum Paylaşmayı Durdur' : 'Konum Paylaş'}</span>
        </button>
      </div>
    </div>
  );
};

// Helper function to get cookie values
function getCookie(name) {
  const cookies = document.cookie.split(';');
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    const cookieName = cookie.substring(0, cookie.indexOf('='));
    if (cookieName === name) {
      return cookie.substring(cookieName.length + 1);
    }
  }
  return null;
}

export default KonumPaylas;
