import React, { useState, useEffect, useCallback } from 'react';
import './App.css';
import axios from 'axios';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';

const containerStyle = {
  width: '60em',
  height: '30em',
};

function RouteTracking() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCXTw1JHaJ1Bh5OCB8E4hYEdX6y5032sQc",
  });

  const [map, setMap] = useState(null);
  const [positions, setPositions] = useState([]);
  const [services, setServices] = useState([]);
  const [schools, setSchools] = useState([]); 
  const [selectedSchool, setSelectedSchool] = useState(''); 
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [selectedMarkerIndex, setSelectedMarkerIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [allServices, setAllServices] = useState(false);
  const [timeOfDay, setTimeOfDay] = useState('');
  const [selectedServiceIds, setSelectedServiceIds] = useState([]); // Track multiple selected services

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get('https://servisrehberim.com/admin/getServiceDetails.php', {
          params: {
            page: currentPage,
            perPage: perPage,
          },
        });
        const responseArray = Object.values(response.data);
        setServices(responseArray[0]);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    fetchServices();
  }, [currentPage]);

  useEffect(() => {
    const fetchSchools = async () => {
      try {
        const response = await axios.get('https://servisrehberim.com/admin/getCollegeOptions.php');
        setSchools(response.data);
      } catch (error) {
        console.error('Error fetching schools:', error);
      }
    };

    fetchSchools();
  }, []);

  const fetchPositions = useCallback(async () => {
    try {
      // If no services are selected and "Select All" isn't active, don't fetch data
      if (selectedServiceIds.length === 0 && !allServices) return;
  
      setIsLoading(true);
  
      let allPositions = [];
      
      if (allServices) {
        // Fetch positions for all services when "Hepsi" (Select All) is selected
        const response = await axios.get('https://servisrehberim.com/admin/getServiceStudents.php', {
          params: {
            getAll: "true", // Indicate fetching all services
            time_of_day: timeOfDay ? timeOfDay : 'cift-yon',
            service_id: '-1',
            school: selectedSchool,
          },
        });
  
        const data = response.data.items || response.data;
        allPositions = data.map((item) => ({
          lat: parseFloat(item.lat) ?? 0.00,
          lng: parseFloat(item.lng) ?? 0.00,
          fullName: item.fullName,
        }));
      } else {
        // Fetch data for each selected service
        for (const serviceId of selectedServiceIds) {
          const response = await axios.get('https://servisrehberim.com/admin/getServiceStudents.php', {
            params: {
              service_id: serviceId,
              time_of_day: timeOfDay,
              school: selectedSchool,
            },
          });
  
          const data = response.data.items || response.data;
          const newPositions = data.map((item) => ({
            lat: parseFloat(item.lat) ?? 0.00,
            lng: parseFloat(item.lng) ?? 0.00,
            fullName: item.fullName,
          }));
  
          allPositions = [...allPositions, ...newPositions];
        }
      }
  
      setPositions(allPositions);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }
  }, [selectedServiceIds, allServices, timeOfDay, selectedSchool]);
  

  useEffect(() => {
    fetchPositions();
  }, [fetchPositions]);

  const handleRadioChange = (event) => {
    setTimeOfDay(event.target.value);
  };

  const handleServiceSelection = (serviceId, isChecked) => {
    setSelectedServiceIds((prevSelectedServiceIds) => {
      if (isChecked) {
        return [...prevSelectedServiceIds, serviceId]; // Add service
      } else {
        return prevSelectedServiceIds.filter((id) => id !== serviceId); // Remove service
      }
    });
    setAllServices(false); // Disable "select all" if specific services are selected
  };

  const handleFetchAllServices = () => {
    setAllServices(true);
    setSelectedServiceIds([]); // Clear selected services when "All Services" is selected
  };

  const handleCloseInfoWindow = () => {
    setSelectedMarkerIndex(null);
  };

  const onLoad = useCallback((map) => {
    setMap(map);
  }, []);

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const handleMarkerClick = (markerIndex) => {
    setSelectedMarkerIndex(markerIndex);
  };

  const handleSchoolChange = (event) => {
    setSelectedSchool(event.target.value);
  };

  return isLoaded ? (
    <div className='flex flex-col'>
      {/* School selection dropdown */}
      <div className="dropdown ml-44 mb-3">
        <label htmlFor="school-select">Okul Seç: </label>
        <select id="school-select" onChange={handleSchoolChange} value={selectedSchool}>
          <option value="">Bütün Okullar</option>
          {schools.map((school) => (
            <option key={school.id} value={school.id}>
              {school.name}
            </option>
          ))}
        </select>
      </div>

      <div className="radio-buttons ml-44 mb-3">
        <input
          type="radio"
          id="morning"
          name="time-of-day"
          value="tek-yon-sabah"
          checked={timeOfDay === 'tek-yon-sabah'}
          onChange={handleRadioChange}
          className='mr-1'
        />
        <label htmlFor="morning">Sabah</label>
        <input
          type="radio"
          id="evening"
          name="time-of-day"
          value="tek-yon-aksam"
          checked={timeOfDay === 'tek-yon-aksam'}
          onChange={handleRadioChange}
          className='mr-1 ml-4'
        />
        <label htmlFor="evening">Akşam</label>
        <input
          type="radio"
          id="allTimeOfDay"
          name="time-of-day"
          value="cift-yon"
          checked={timeOfDay === 'cift-yon'}
          onChange={handleRadioChange}
          className='mr-1 ml-4'
        />
        <label htmlFor="allTimeOfDay">Hepsi</label>
      </div>

      <div className="flex justify-center">
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={positions[0] || { lat: 39.92959213536456, lng: 32.8458355478295 }}
          zoom={10}
          onLoad={onLoad}
          onUnmount={onUnmount}
        >
          {positions.map((position, index) => (
            position.lat !== 39.92959213536456 && position.lng !== 32.8458355478295 &&
            <Marker
              key={index}
              position={position}
              onClick={() => handleMarkerClick(index)}
            />
          ))}
          {selectedMarkerIndex !== null && positions[selectedMarkerIndex] && (
            <InfoWindow
              position={positions[selectedMarkerIndex]}
              onCloseClick={handleCloseInfoWindow}
            >
              <div>
                {positions[selectedMarkerIndex].fullName || 'No Name Available'}
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
        <div className="flex flex-col">
          <div className="legend">
            <label key={'allServices'}>
              <input
                type="checkbox"
                value={'hepsi'}
                onChange={(e) => {
                  if (e.target.checked) {
                    handleFetchAllServices();
                  } else {
                    setSelectedMarkerIndex(null);
                    setPositions([]);
                    setAllServices(false);
                  }
                }}
              />
              Hepsi
            </label>
            {services.map((service, index) => (
              <label key={index}>
                <input
                  type="checkbox"
                  value={service.id}
                  onChange={(e) => handleServiceSelection(service.id, e.target.checked)}
                  checked={selectedServiceIds.includes(service.id)}
                  disabled={allServices}
                />
                {service.plaka}
              </label>
            ))}
          </div>
        </div>
      </div>
    </div>
  ) : <div>Loading...</div>;
}

export default React.memo(RouteTracking);
