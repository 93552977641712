import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import AddServiceForm from './AddServiceForm';
import UpdateServiceForm from './UpdateServiceForm';
import AddRoute from './AddRoute';
import { FcOk } from "react-icons/fc";
import { FcCancel } from "react-icons/fc";
import { BiSolidEdit } from "react-icons/bi";
import { FaRegMap } from "react-icons/fa";
import { GrDocumentPdf } from "react-icons/gr";
import { AiTwotoneDelete } from "react-icons/ai";
import { Page, Text, View, Document, StyleSheet, PDFDownloadLink, PDFViewer, Font } from '@react-pdf/renderer';

// Register a font that supports the Turkish character "ı"
import DejaVuSans from './fonts/DejaVuSans.ttf'; // Make sure to include this font in your project

Font.register({
  family: 'DejaVuSans',
  src: DejaVuSans,
});

const styles = StyleSheet.create({
  title: {
    fontSize: 10,
    fontWeight: 'bold',
    marginBottom: 5,
  },
  info: {
    fontSize: 8,
    marginBottom: 3,
  },
  table: {
    marginTop: 10,
    width: '100%',
    marginBottom: 10
  },
  tableHeader: {
    flexDirection: 'row',
    backgroundColor: '#eee',
    padding: 6,
    height: 50,
    borderBottom: '1px solid #ddd',
    borderTop: '1px solid #ddd',
  },
  tableHeaderCell: {
    flexDirection: 'row',
    flex: 1,
    fontSize: 10,
    padding: 6,
    height: 50,
    fontWeight: 'bold',
    paddingHorizontal: 4,
    borderBottom: '1px solid #ddd',
    borderTop: '1px solid #ddd',
    backgroundColor: '#eee',
    textAlign: 'center',
    borderRight: '1px solid #ddd',
  },
  tableRow: {
    flexDirection: 'row',
    borderBottom: '1px solid #ddd',
  },
  tableCell: {
    flex: 1,
    fontSize: 8,
    paddingHorizontal: 4,
    height: 30,
    textAlign: 'center',
    borderRight: '1px solid #ddd',
  },
  addressCell: {
    flex: 2,
    fontSize: 8,
    paddingHorizontal: 4,
    textAlign: 'left',
  },
});

// Define the custom styles for DataTable
const customStyle = {
  cells: {
    style: {
      minHeight: '80px', // Increased height for better spacing
      fontSize: '1.2em',  // Slightly smaller font size to avoid collision
      wordWrap: 'break-word', // Ensure text wraps within the cell
    },
  },
  headRow: {
    style: {
      fontWeight: 'bold',
      fontSize: '1.6em',
    },
  },
};

// Define a mapping for the column headers
const columnMapping = {
  fullName: 'Tam İsim',
  fname: 'Baba Adı',
  fphone: 'Baba Tel No',
  mname: 'Anne Adı',
  mphone: 'Anne Tel No',
  address: 'Adres',
  school_option: 'Okul',
  sinif: 'Sınıf',
  direction_option: 'Yon',
};

const StudentPDFDocument = ({ service, studentData }) => {
  // Filter out unnecessary keys
  const keysToExclude = ['id', 'lat', 'lng', 'parentID', 'distance', 'price'];
  const filteredKeys = Object.keys(studentData[0] || {}).filter(key => !keysToExclude.includes(key));

  return (
    <Document>
      <Page size="A4" style={{ fontFamily: 'DejaVuSans' }}> {/* Use the registered font */}
        {/* Display the route name in the top-right corner */}
        <View style={{ flexDirection: 'row', justifyContent: 'space-between' }}>
          <Text style={styles.title}>{`Plaka: ${service.plaka}`}</Text>
          <Text style={[styles.title, { textAlign: 'right', fontSize: 20 }]}>{`Rota: ${service.routeName}`}</Text> {/* Add route name here */}
        </View>
        <View style={styles.info}>
          <Text>Sürücü: {service.surucuAdi}</Text>
          <Text>Sürücü Tel No: {service.surucuIletisim}</Text>
          <Text>Rehber: {service.yardimciAdi}</Text>
          <Text>Rehber Tel No: {service.yardimciIletisim}</Text>
        </View>
        <Text style={styles.title}>Öğrenci Listesi:</Text>
        <View style={styles.table}>
          <View style={styles.tableHeaderCell}>
            <Text style={styles.tableCell}>No</Text> {/* Add "No" column for numbering */}
            {filteredKeys.map((key) => (
              <Text key={key} style={key === 'address' ? styles.addressCell : styles.tableCell}>
                {columnMapping[key] || key} {/* Use custom column name or fall back to key */}
              </Text>
            ))}
          </View>
          {studentData.map((student, index) => (
            <View wrap={false} key={index} style={styles.tableRow}>
              <Text style={styles.tableCell}>{index + 1}</Text> {/* Numbering starts from 1 */}
              {filteredKeys.map((key) => (
                <Text wrap={false} key={key} style={key === 'address' ? styles.addressCell : styles.tableCell}>
                  {student[key]}
                </Text>
              ))}
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
};


function Services() {
  const [services, setServices] = useState([]);
  const [service, setService] = useState({});
  const [studentData, setStudentData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10); // Adjust as needed
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isRouteModalOpen, setIsRouteModalOpen] = useState(false);
  const [isPdfModalOpen, setIsPdfModalOpen] = useState(false);

  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);

  const handleOpenEditModal = (row) => {
    setIsEditModalOpen(true);
    setService(row);
  };
  const handleCloseEditModal = () => setIsEditModalOpen(false);

  const handleOpenRouteModal = (row) => {
    setIsRouteModalOpen(true);
    setService(row);
  };
  const handleCloseRouteModal = () => setIsRouteModalOpen(false);

  const removeService = async (id) => {
    try {
      const response = await axios.post('https://servisrehberim.com/admin/deleteService.php', {
        service_id: id,
      },
      {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    console.log(response);
      if (response.status === 200) {
        // Handle success
        console.log('Student added to service');
        await fetchServices();
      } else {
        // Handle error
        console.error('An error occurred while deleting the student');
      }
    } catch (error) {
      console.error('An error occurred while deleting the student:', error);
    }
  }

  const handleOpenPdfModal = async (row) => {
    setService(row);
    try {
      const response = await axios.get('https://servisrehberim.com/admin/getServiceStudents.php', {
        params: {
          service_id: row.id,
          action: 'getForService',
        },
      });
      
      // Check if the response contains data
      if (response.data && response.data.length > 0) {
        setStudentData(response.data);
        setIsPdfModalOpen(true);
      } else {
        // Handle case where no data is returned
        setStudentData([]);
        alert('Bu servise atanmis öğrenci yok.'); // Show a message to the user
      }
    } catch (error) {
      console.error('Error fetching student data:', error);
      alert('An error occurred while fetching student data.'); // Show a message to the user
    }
  };
  

  const handleClosePdfModal = () => setIsPdfModalOpen(false);

  const columns = [
    { name: "Araç Plakası", selector: (row) => row.plaka, sortable: true, wrap: true },
    { name: "Sürücü Adı", selector: (row) => row.surucuAdi, wrap: true },
    { name: "Yardımcı Adı", selector: (row) => row.yardimciAdi, wrap: true },
    { name: "Sürücü İletişim", selector: (row) => row.surucuIletisim, wrap: true },
    { name: "Yardımcı İletişim", selector: (row) => row.yardimciIletisim, wrap: true },
    {
      name: "Durum",
      selector: (row) => row.durum === "Aktif" ? <FcOk style={{ fontSize: '1.5em' }} /> : <FcCancel style={{ fontSize: '1.5em' }} />,
    },
    {
      name: "",
      selector: (row) => (
        <div style={{ display: 'flex', gap: '0.5em' }}> {/* Use Flexbox and gap to space out buttons */}
          <button onClick={() => handleOpenEditModal(row)}>
            <BiSolidEdit style={{ fontSize: '2em' }} />
          </button>
          <button onClick={() => handleOpenRouteModal(row)}>
            <FaRegMap style={{ fontSize: '2em' }} />
          </button>
          <button onClick={() => handleOpenPdfModal(row)}>
            <GrDocumentPdf style={{ fontSize: '2em' }} />
          </button>
          <button onClick={() => removeService(row.id)}>
            <AiTwotoneDelete style={{ fontSize: '2em' }} />
          </button>
        </div>
      ),
    },
  ];

  function getCookie(name) {
    const cookies = document.cookie.split(';');
  
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      const cookieName = cookie.substring(0, cookie.indexOf('='));
  
      if (cookieName === name) {
        return cookie.substring(cookieName.length + 1);
      }
    }
  
    return null;
  }
  const checkIsLoggedIn = () => {
    const isValidCookie = getCookie('PHPSESSID');
    return isValidCookie;
  };
  const fetchServices = async () => {
    try {
      const response = await axios.get('https://servisrehberim.com/admin/getServiceDetails.php', {
        params: {
          page: currentPage,
          perPage: perPage,
        },
      });
      setServices(Object.values(response.data)[0]);
    } catch (error) {
      console.error('Error fetching services:', error);
    }
  };
  useEffect(() => {
    const isLoggedIn = checkIsLoggedIn();
    if (!isLoggedIn && window.location.pathname != '/') {
      // Redirect to the landing page if the user is not logged in
      window.location.href = '/';
    }
    fetchServices();
  }, [currentPage]);

  const handlePageChange = (page) => setCurrentPage(page);

  return (
    <div>
      <div className='flex justify-end mr-12'>
        <button
          className='border border-2 border-green-600 bg-green-500 w-36 h-12 text-white rounded-lg text-xl font-bold'
          onClick={handleOpenModal}>
          Servis Ekle
        </button>
        {isModalOpen && <AddServiceForm title="Servis Ekle" isOpen={isModalOpen} onClose={handleCloseModal} />}
      </div>
      {isEditModalOpen && <UpdateServiceForm isOpen={isEditModalOpen} onClose={handleCloseEditModal} service={service} />}
      {isRouteModalOpen && <AddRoute isOpen={isRouteModalOpen} onClose={handleCloseRouteModal} service={service} />}

      <div className='justify-center m-10'>
        <DataTable
          columns={columns}
          data={services}
          customStyles={customStyle}
          pagination
          paginationComponentOptions={{
            rowsPerPageText: 'Satır başına',
            rangeSeparatorText: 'ile',
            selectAllRowsItem: true,
            rowsPerPageOptions: [10, 25, 50, 100],
          }}
          onPerPageChange={setPerPage}
          perPage={perPage}
          onPageChange={handlePageChange}
        />
      </div>

      {isPdfModalOpen && (
        <div className='modal' style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', backgroundColor: 'rgba(0,0,0,0.5)' }}>
          <div className='modal-content' style={{ width: '90%', height: '90%', backgroundColor: 'white', padding: '20px', borderRadius: '8px' }}>
            <button onClick={handleClosePdfModal}>Close</button>
            <PDFViewer style={{ width: '100%', height: '80vh' }}>
              <StudentPDFDocument service={service} studentData={studentData} />
            </PDFViewer>
            <PDFDownloadLink document={<StudentPDFDocument service={service} studentData={studentData} />} fileName="student-data.pdf">
              {({ loading }) => (loading ? 'Loading document...' : (
      <button
        style={{
          backgroundColor: '#4CAF50', // Green background color
          color: 'white', // White text color
          border: 'none', // Remove default border
          borderRadius: '5px', // Rounded corners
          padding: '10px 20px', // Add padding
          fontSize: '16px', // Font size
          cursor: 'pointer', // Pointer cursor on hover
          marginTop: '10px', // Margin from top
          textDecoration: 'none', // Remove underline
        }}
      >
        Download PDF
      </button>
    ))}
            </PDFDownloadLink>
          </div>
        </div>
      )}
    </div>
  );
}

export default Services;
