import React, { useEffect, useState } from 'react';
import DataTable from "react-data-table-component";
import axios from 'axios';
import { CiCircleRemove } from "react-icons/ci";
import Modal from 'react-modal';

  const customStyle = {
    cells: {
        style: {
            minHeight: '70px',
            fontSize: '1.3em',
            paddingTop: '0.8em',
            paddingBottom: '0.8em',
            paddingLeft: '1em',
        },
    },
    headRow: {
        style: {
            fontWeight: 'bold',
            fontSize: '1.6em',
        },
    },
  }

const ServiceStudentListModal = ({ isOpen, onClose, service }) => {

    const [students, setStudents] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10); // Adjust as needed
    const [searchText, setSearchText] = useState(''); // New state for search input
    const [filteredStudents, setFilteredStudents] = useState([]); // New state for filtered data

    const columns = [
        { name: "Öğrenci Adı", selector: (row) =>  row.fullName, sortable: true, wrap: true  },
        { name: "Baba Adı", selector: (row) =>  row.fname, wrap: true  },
        { name: "Baba İletişim", selector: (row) =>  row.fphone, wrap: true  },
        { name: "Anne Adı", selector: (row) => row.mname, wrap: true  },
        { name: "Anne İletişim", selector: (row) =>   row.mphone, wrap: true  },
        { name: "Adres", selector: (row) =>   row.address, sortable: true, wrap: true },
        { name: "Okul Adı", selector: (row) =>   row.school_option, sortable: true, wrap: true },
        { name: "Sınıf", selector: (row) =>   row.sinif, wrap: true  },
        { name: "", selector: (row) =>  <div> <button onClick={() => handleRemoveStudentFromService(row.id)}><CiCircleRemove style={{fontSize:'2em', paddingLeft:'0.3em'}} /></button> </div>},
    ];
    

        const fetchStudents = async () => {
          try {
            const response = await axios.get('https://servisrehberim.com/admin/getServiceStudents.php', {
              params: {
                page: currentPage,
                perPage: perPage,
                service_id: service.id,
                action: 'getForService',
              },
            });
            const responseArray = response.data;
            setStudents(responseArray);
            setFilteredStudents(responseArray);
          } catch (error) {
            console.error('Error fetching students:', error);
          }
        };

        useEffect(() => {
            fetchStudents();
        }, [currentPage]);
    
      const handlePageChange = (page) => {
        setCurrentPage(page);
      };

      const removeStudent = async (id) => {
        try {
          const response = await axios.post('https://servisrehberim.com/admin/removeStudentFromService.php', {
            student_id: id,
            servis_id: service.id,
          },
          {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        console.log(response);
          if (response.status === 200) {
            // Handle success
            console.log('Student added to service');
            await fetchStudents();
          } else {
            // Handle error
            console.error('An error occurred while deleting the student');
          }
        } catch (error) {
          console.error('An error occurred while deleting the student:', error);
        }
      }
    
      const handleRemoveStudentFromService = (studentId) => {
        // Your addStudent logic with studentId
        removeStudent(studentId);
      };
      const handleSearchChange = (event) => {
        setSearchText(event.target.value);
        if (event.target.value === '') {
            // If search input is cleared, reset the filtered data
            setFilteredStudents(students);
        } else {
            const filteredData = students.filter((student) =>
                Object.values(student).some((value) =>
                    value.toString().toLowerCase().includes(event.target.value.toLowerCase())
                )
            );
            setFilteredStudents(filteredData);
        }
    };

      return (
        <div class={`modal ${isOpen ? 'modal-open' : ''}`}>
          <Modal isOpen={isOpen} onRequestClose={onClose}
          style={{
            content: {
                width: '100%', // Adjust as needed
                maxWidth: '100em', // Set a maximum width to prevent it from becoming too large
                height: '100%', // Adjust as needed
                maxHeight: '50em', // Set a maximum height
                margin: 'auto',
                padding: '20px',
            },
          }}
          >
          <div className='flex justify-end mr-12'>
                {/* Search bar */}
                <input
                    type="text"
                    placeholder="Ara..."
                    value={searchText}
                    onChange={handleSearchChange}
                    className="p-2 border border-gray-400 rounded mb-3"
                />
            </div>
            <div className="modal-header">
              <h2 class='font-bold text-3xl'>Öğrenci Listesi</h2>
              <button className="close-button" onClick={onClose}>✖</button>
            </div>
            <div className="modal-body">
            <div class='justify-center m-10'>
        <DataTable
          columns={columns}
          data={filteredStudents}
          customStyles={customStyle}
          pagination
          paginationComponentOptions={{
            rowsPerPageText: 'Satır başına',
            rangeSeparatorText: 'ile',
            selectAllRowsItem: true,
            rowsPerPageOptions: [10, 25, 50, 100],
          }}
          onPerPageChange={setPerPage}
          perPage={perPage}
          onPageChange={handlePageChange}
          handleAddStudent={handleRemoveStudentFromService}
        />
      </div>
            </div>
          </Modal>
        </div>
      );
};

export default ServiceStudentListModal;