import React, { useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';

function AddRoute({ isOpen, onClose, service }) {
  const [id, setId] = useState(service.id);
  const [plaka, setPlaka] = useState(service.plaka);
  const [okulAdi, setOkulAdi] = useState(service.schoolName || '');
  const [guzergah, setGuzergah] = useState(service.routeName || '');
  const [warning, setWarning] = useState('');

  const onSubmit = async ({ okulAdi, guzergah }) => {
    try {
        const response = await axios.post('https://servisrehberim.com/admin/assignServiceToRoute.php', {
            id: id,
            okulAdi: okulAdi,
            guzergah: guzergah,
        },
        {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });

        if (response.status === 200) {
            // Handle success
            onClose();
            window.location.reload();
        } else {
            // Handle error
            setWarning('Bir hata oluştu. Lütfen tekrar deneyin.');
        }
    } catch (error) {
        setWarning('Bir hata oluştu. Lütfen tekrar deneyin.');
        console.error('An error occurred', error);
    }
};

  const handleSubmit = (event) => {
    event.preventDefault();
    // Perform form validation or other actions here
    onSubmit({ okulAdi, guzergah });
  };

  return (
    <div class={`modal ${isOpen ? 'modal-open' : ''}`}>
      <Modal isOpen={isOpen} onRequestClose={onClose}
      style={{
        content: {
            width: '50%', // Adjust as needed
            maxWidth: '600px', // Set a maximum width to prevent it from becoming too large
            height: '80%', // Adjust as needed
            maxHeight: '700px', // Set a maximum height
            margin: 'auto',
            padding: '20px',
        },
      }}
      >
        <div className="modal-header">
          <h2 class='font-bold text-3xl'>Güzergah Ekle/Düzenle</h2>
          <button className="close-button" onClick={onClose}>✖</button>
        </div>
        <div className="modal-body">
          <form onSubmit={handleSubmit}>
            <div className="space-y-5">
              <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                <div className="sm:col-span-4">
                  <label htmlFor="plaka" className="block text-lg font-medium leading-6 text-gray-900">Araç Plakası</label>
                  <input type="text" id="plaka" name="plaka" value={plaka} onChange={(e) => setPlaka(e.target.value)} className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" required readOnly />
                </div>
                <div className="sm:col-span-4">
                  <label htmlFor="okulAdi" className="block text-lg font-medium leading-6 text-gray-900">Okul Adı</label>
                  <input type="text" id="okulAdi" name="okulAdi" value={okulAdi} onChange={(e) => setOkulAdi(e.target.value)} className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" required />
                </div>
                <div className="sm:col-span-4">
                  <label htmlFor="guzergah" className="block text-lg font-medium leading-6 text-gray-900">Güzergah</label>
                  <input type="text" id="guzergah" name="guzergah" value={guzergah} onChange={(e) => setGuzergah(e.target.value)} className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm" required />
                </div>
              </div>
            </div>
            <div className="flex justify-end space-x-4">
              <button type="button" className="btn btn-secondary text-lg border border-solid border-red-500 bg-red-500 rounded-lg w-24 h-10 font-bold text-white" onClick={onClose}>İptal</button>
              <button type="submit" className="btn btn-primary text-lg border border-solid border-blue-800 bg-blue-800 rounded-lg w-24 h-10 font-bold text-white" onClick={handleSubmit}>Kaydet</button>
            </div>
            <div className="flex flex-row justify-end space-x-4 pt-5">
                { warning && <p className="text-red-500 justify-end">{warning}</p> }
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}

export default AddRoute;