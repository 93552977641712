import React, { useEffect, useState } from 'react';
import DataTable from "react-data-table-component";
import { FaRegTrashAlt } from "react-icons/fa";
import axios from 'axios';

const handleDeleteStudent = (studentId) => {
    deleteStudent(studentId);
};

const columns = [
    { name: "Öğrenci Adı", selector: (row) => row.fullName, sortable: true, wrap: true },
    { name: "Baba Adı", selector: (row) => row.fname, wrap: true },
    { name: "Baba İletişim", selector: (row) => row.fphone, wrap: true },
    { name: "Anne Adı", selector: (row) => row.mname, wrap: true },
    { name: "Anne İletişim", selector: (row) => row.mphone, wrap: true },
    { name: "Adres", selector: (row) => row.address, sortable: true, wrap: true },
    { name: "Okul Adı", selector: (row) => row.school_option, sortable: true, wrap: true },
    { name: "Yon", selector: (row) => row.direction_option, sortable: true, wrap: true },
    { name: "Sınıf", selector: (row) => row.sinif, wrap: true },
    {
        name: "",
        selector: (row) => (
            <div>
                <button onClick={() => handleDeleteStudent(row.id)}>
                    <FaRegTrashAlt style={{ fontSize: '2em', paddingLeft: '0.3em' }} />
                </button>
            </div>
        ),
    },
];

const customStyle = {
    cells: {
        style: {
            minHeight: '70px',
            fontSize: '1.3em',
            paddingTop: '0.8em',
            paddingBottom: '0.8em',
            paddingLeft: '1em',
        },
    },
    headRow: {
        style: {
            fontWeight: 'bold',
            fontSize: '1.5em',
        },
    },
};

const deleteStudent = (id) => {
    try {
        const response = 'deleteStudent';
        // Uncomment below line to use real API call
        // await axios.post('https://servisrehberim.com/admin/deleteStudent.php', { id: id });
        console.log(response);
        if (response.status === 200) {
            // Handle success
            window.location.reload();
        } else {
            // Handle error
            console.error('An error occurred while deleting the student');
        }
    } catch (error) {
        console.error('An error occurred while deleting the student:', error);
    }
};


const checkIsLoggedIn = () => {
    const isValidCookie = getCookie('PHPSESSID');
    return isValidCookie;
};

function getCookie(name) {
    const cookies = document.cookie.split(';');
  
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      const cookieName = cookie.substring(0, cookie.indexOf('='));
  
      if (cookieName === name) {
        return cookie.substring(cookieName.length + 1);
      }
    }
  
    return null;
  }


const Students = () => {
    const [students, setStudents] = useState([]);
    const [filteredStudents, setFilteredStudents] = useState([]); // New state for filtered data
    const [searchText, setSearchText] = useState(''); // New state for search input
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);

    useEffect(() => {
        const isLoggedIn = checkIsLoggedIn();
        if (!isLoggedIn && window.location.pathname != '/') {
          // Redirect to the landing page if the user is not logged in
          window.location.href = '/';
        }
        const fetchStudents = async () => {
            try {
                const response = await axios.get('https://servisrehberim.com/admin/getAllStudents.php', {
                    params: {
                        page: currentPage,
                        perPage: perPage,
                    },
                });
                const responseArray = Object.entries(response.data);
                setStudents(responseArray[0][1]);
                setFilteredStudents(responseArray[0][1]); // Initialize filtered students
            } catch (error) {
                console.error('Error fetching students:', error);
            }
        };

        fetchStudents();
    }, [currentPage, perPage]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    // Handle search input change
    const handleSearchChange = (event) => {
        setSearchText(event.target.value);
        if (event.target.value === '') {
            // If search input is cleared, reset the filtered data
            setFilteredStudents(students);
        } else {
            const filteredData = students.filter((student) =>
                Object.values(student).some((value) =>
                    value.toString().toLowerCase().includes(event.target.value.toLowerCase())
                )
            );
            setFilteredStudents(filteredData);
        }
    };

    return (
        <div>
            <div className='flex justify-end mr-12'>
                {/* Search bar */}
                <input
                    type="text"
                    placeholder="Ara..."
                    value={searchText}
                    onChange={handleSearchChange}
                    className="p-2 border border-gray-400 rounded mb-3"
                />
            </div>
            <div className='justify-center m-10'>
                <DataTable
                    columns={columns}
                    data={filteredStudents} // Use filtered data
                    customStyles={customStyle}
                    pagination
                    paginationComponentOptions={{
                        rowsPerPageText: 'Satır başına',
                        rangeSeparatorText: 'ile',
                        selectAllRowsItem: true,
                        rowsPerPageOptions: [10, 25, 50, 100],
                    }}
                    onPerPageChange={setPerPage}
                    perPage={perPage}
                    onPageChange={handlePageChange}
                    handleDeleteStudent={handleDeleteStudent}
                />
            </div>
        </div>
    );
};

export default Students;
