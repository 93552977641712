import React, { useState, useEffect, useCallback, useMemo } from 'react';
import './App.css';
import './takipEt.css';
import axios from 'axios';
import { GoogleMap, useJsApiLoader, Marker, InfoWindow } from '@react-google-maps/api';

const containerStyle = {
  width: '60em',
  height: '30em',
};

const checkIsLoggedIn = () => {
  const isValidCookie = getCookie('PHPSESSID');
  return isValidCookie;
};

function getCookie(name) {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    const cookieName = cookie.substring(0, cookie.indexOf('='));

    if (cookieName === name) {
      return cookie.substring(cookieName.length + 1);
    }
  }

  return null;
}

function TakipEt() {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: "AIzaSyCXTw1JHaJ1Bh5OCB8E4hYEdX6y5032sQc", // Replace with your actual API key
  });

  const [map, setMap] = useState(null);
  const [positions, setPositions] = useState([{ lat: 39.92959213536456, lng: 32.8458355478295 }]);
  const [isLoading, setIsLoading] = useState(false);
  const [serviceId] = useState(getCookie('SERVICE_ID'));
  const [username] = useState(getCookie('SERVICE_USER'));
  const [priceInfo, setPriceInfo] = useState(null);
  const [directionInfo, setDirectionInfo] = useState(null);
  const [schoolInfo, setScoolInfo] = useState(null);
  const [fname, setFnameInfo] = useState(null);
  const [fphone, setFphoneInfo] = useState(null);
  const [mname, setMnameInfo] = useState(null);
  const [mphone, setMphoneInfo] = useState(null);
  const [plaka, setPlakaInfo] = useState(null);
  const [surucu, setSurucuInfo] = useState(null);
  const [surucuTel, setSurucuTelInfo] = useState(null);
  const [yardimci, setYardimciInfo] = useState(null);
  const [yardimciTel, setYardimciTelInfo] = useState(null);

  const handleFetchAllServices = useCallback(async () => {
    setIsLoading(true);
  
    try {
      // Fetching price and school info
      const priceResponse = await axios.get(`https://servisrehberim.com/admin/getPriceInfo.php?username=${username}&service_id=${serviceId}`);
      const priceInf = priceResponse.data;  // Access the data properly
      console.log(priceInf);
  
      setPriceInfo(priceInf.price);
      setDirectionInfo(priceInf.direction);
      setScoolInfo(priceInf.school);
      setFnameInfo(priceInf.fname)
      setFphoneInfo(priceInf.fphone)
      setMnameInfo(priceInf.mname)
      setMphoneInfo(priceInf.mphone)
      setPlakaInfo(priceInf.plaka)
      setSurucuInfo(priceInf.surucu)
      setSurucuTelInfo(priceInf.surucuTel)
      setYardimciInfo(priceInf.yardimci)
      setYardimciTelInfo(priceInf.yardimciTel)
      // Exit early if serviceId is missing
      if (!serviceId) {
        setIsLoading(false);
        return;
      }
  
      // Fetching positions
      const response = await axios.get(`https://servisrehberim.com/admin/getCurrentPosition.php?service_id=${serviceId}`);
      const data = response.data;  // Access the response data correctly
      console.log(data);
  
      // Map over the response to set positions
      setPositions([{
        lat: parseFloat(data.latitude),  // Ensure lat and lng are numbers
        lng: parseFloat(data.longitude),
        fullName: priceInf.fullName,
      }]);
  
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [serviceId, username]);
  
  useEffect(() => {
    if (!checkIsLoggedIn() && window.location.pathname != '/') {
      // Redirect to the landing page if the user is not logged in
      window.location.href = '/';
    }
    // Fetch data immediately upon mounting
    handleFetchAllServices();
  
    // Set up the interval for repeated fetching
    const intervalId = setInterval(handleFetchAllServices, 10000); // 10 seconds
  
    return () => clearInterval(intervalId); // Clean up on unmount
  }, [handleFetchAllServices]); // Include handleFetchAllServices as a dependency

  const onUnmount = useCallback(() => {
    setMap(null);
  }, []);

  const onLoad = useCallback(() => {
    setMap(map);
  }, []);

  return (
    <div className='flex flex-col'>
      <div className="flex h-full">
        <div className="w-3/4 flex flex-col items-center">
          {isLoaded ? (
            serviceId !== null ? (
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={positions[0]}
                zoom={10}
                onUnmount={onUnmount}
                onLoad={onLoad}
              >
                {positions.map((position, index) => (
                  position.lat !== 39.92959213536456 && position.lng !== 32.8458355478295 &&
                    <Marker key={index} position={position} />
                ))}
              </GoogleMap>
            ) : (
              <p class='ml-5'>Öğrencinin servis kaydı bulunmamaktadır.</p>
            )
          ) : (
            <div>Loading...</div>
          )}
        </div>
        
        <div className="p-4 bg-gray-50 rounded-lg shadow-lg border border-gray-200 w-1/4 ml-5 rounded-lg flex flex-col items-center justify-end">
          <div className="text-gray-600 space-y-3">
            <p><b>Plaka:</b> {plaka}</p>
            <p><b>Sürücü:</b> {surucu}</p>
            <p><b>Sürücü Telefon:</b> {surucuTel}</p>
            <p><b>Yardımcı:</b> {yardimci}</p>
            <p><b>Yardımcı Telefon:</b> {yardimciTel}</p>
            <p><b>Yon:</b> {directionInfo}</p>

            <p><b>Anne:</b> {mname}</p>
            <p><b>Anne Telefon:</b> {mphone}</p>

            
            <p><b>Baba:</b> {fname}</p>
            <p><b>Baba Telefon:</b> {fphone}</p>

            <p><b>Fiyat:</b> {priceInfo}</p>
            <p><b>IBAN:</b> {
              (schoolInfo && schoolInfo.includes('doktorlar')) ?
                "TR75 0006 2000 5970 0006 2948 87" : 
              (schoolInfo && schoolInfo.includes('dusunur')) ?
                "TR10 0020 5000 0994 8556 2000 01" : ""
            }
            </p>
            <p><b>Sözleşmeniz:</b> {
              (schoolInfo && schoolInfo.includes('doktorlar')) ?
              <a className='text-blue-800 underline' href='/sozlesme/doktorlar.pdf' target='_blank'>Servis Kullanım Sözleşmesi</a>: 
              (schoolInfo && schoolInfo.includes('dusunur')) ?
              "" : ""
            }
            </p>
          </div> 
        </div>
      </div>
    </div>
  );
}

export default React.memo(TakipEt);
