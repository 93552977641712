import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import AddServiceForm from './AddServiceForm';
import Students from './Students'; // Assuming this is a separate component
import UpdateServiceForm from './UpdateServiceForm';
import AddRoute from './AddRoute';
import { FcOk } from "react-icons/fc";
import { FcCancel } from "react-icons/fc";
import { BiSolidEdit } from "react-icons/bi";
import { FaRegMap } from "react-icons/fa";
import { PiStudentFill } from "react-icons/pi";
import StudentListModal from './StudentListModal'; // Assuming this component exists
import { IoIosPersonAdd } from "react-icons/io";
import ServiceStudentListModal from './ServiceStudentListModal';


const customStyle = {
  cells: {
      style: {
          minHeight: '70px',
          fontSize: '1.3em',
      },
  },
  headRow: {
      style: {
          fontWeight: 'bold',
          fontSize: '1.6em',
      },
  },
}

function GuzergahYonetimi() {
  const [services, setServices] = useState([]);
  const [service, setService] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10); // Adjust as needed
  const [isStudentModalOpen, setIsStudentModalOpen] = useState(false);
  const [isRouteModalOpen, setIsRouteModalOpen] = useState(false);
  const [isServiceStudentListModalOpen, setIsServiceStudentListOpen] = useState(false);

  function getCookie(name) {
    const cookies = document.cookie.split(';');
  
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      const cookieName = cookie.substring(0, cookie.indexOf('='));
  
      if (cookieName === name) {
        return cookie.substring(cookieName.length + 1);
      }
    }
  
    return null;
  }
  // Student Modal
  const handleOpenStudentModal = (row) => {
    // Potentially use row data here for StudentListModal
    setIsStudentModalOpen(true);
    setService(row); // Set the entire row object
  };
  const handleCloseStudentModal = () => setIsStudentModalOpen(false);
  const handleCloseServiceStudentListModal = () => setIsServiceStudentListOpen(false);

  // Route Modal
  const handleOpenRouteModal = (row) => {
    setIsRouteModalOpen(true);
    setService(row); // Set the entire row object
  };

  const handleServiceStudentListModal = (row) => {
    setIsServiceStudentListOpen(true);
    setService(row); // Set the entire row object
  };
  const handleCloseRouteModal = () => setIsRouteModalOpen(false);

  const columns = [
    { name: "Araç Plakası", selector: (row) =>  row.plaka, sortable: true, wrap: true },
    { name: "Sürücü Adı", selector: (row) =>  row.surucuAdi, wrap: true },
    { name: "Yardımcı Adı", selector: (row) =>  row.yardimciAdi, wrap: true },
    { name: "Sürücü İletişim", selector: (row) => row.surucuIletisim, wrap: true },
    { name: "Yardımcı İletişim", selector: (row) =>   row.yardimciIletisim, wrap: true },    
    { name: "Durum", selector: (row) =>   row.durum === "Aktif"  ? <FcOk class='w-10' style={{fontSize:'1.5em'}}/> : <FcCancel style={{fontSize:'1.5em'}}/> },
    { name: "Okul Adı", selector: (row) => row.schoolName, wrap: true },
    { name: "Güzergah", selector: (row) => row.routeName, wrap: true },
    { name: "", selector: (row) =>  <div>
        <button onClick={() => handleOpenStudentModal(row)}><IoIosPersonAdd style={{fontSize:'2em'}} /></button> 
    </div>},    
    { name: "", selector: (row) =>  <div>
        <button onClick={() => handleOpenRouteModal(row)}><FaRegMap style={{fontSize:'2em'}} /></button>
    </div>},
        { name: "", selector: (row) =>  <div>
          <button onClick={() => handleServiceStudentListModal(row)}><PiStudentFill style={{fontSize:'2em'}} /></button>
      </div>}
  ];

  const checkIsLoggedIn = () => {
    const isValidCookie = getCookie('PHPSESSID');
    return isValidCookie;
  };

  
  useEffect(() => {
    const isLoggedIn = checkIsLoggedIn();
    if (!isLoggedIn && window.location.pathname != '/') {
      // Redirect to the landing page if the user is not logged in
      window.location.href = '/';
    }
    const fetchServices = async () => {
      try {
        const response = await axios.get('https://servisrehberim.com/admin/getServiceDetails.php', {
          params: {
            page: currentPage,
            perPage: perPage,
          },
        });
        const responseArray = Object.values(response.data);
        //console.log(responseArray);
        setServices(responseArray[0]);
      } catch (error) {
        console.error('Error fetching services:', error);
      }
    };

    fetchServices();
  }, [currentPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div>
      {isStudentModalOpen && <StudentListModal isOpen={isStudentModalOpen} onClose={handleCloseStudentModal} service={service} />} 
      {isRouteModalOpen && <AddRoute isOpen={isRouteModalOpen} onClose={handleCloseRouteModal} service={service} />} 
      {isServiceStudentListModalOpen && <ServiceStudentListModal isOpen={isServiceStudentListModalOpen} onClose={handleCloseServiceStudentListModal} service={service}/>}
      <div class='justify-center m-10'>
        <DataTable
          columns={columns}
          data={services}
          customStyles={customStyle}
          pagination
          paginationComponentOptions={{
            rowsPerPageText: 'Satır başına',
            rangeSeparatorText: 'ile',
            selectAllRowsItem: true,
            rowsPerPageOptions: [10, 25, 50, 100],
          }}
          onPerPageChange={setPerPage}
          perPage={perPage}
          onPageChange={handlePageChange}
          // totalRows={totalRows} // Replace with the total number of rows from your API response
        />
      </div>
    </div>
  );
}

export default GuzergahYonetimi;